import React, { useState, useEffect } from "react";
import { data } from "../data/data.jsx";
import { FaWindowClose } from "react-icons/fa";
import BannerBackground from "../assets/home-banner-background.png";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";

const Projects = () => {
  const languages = [
    {
      code: "tr",
      name: "Türkçe",
      country_code: "tr",
    },
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
    {
      code: "ar",
      name: "العربية",
      country_code: "sa",
    },
    {
      code: "az",
      name: "Azərbaycan",
      country_code: "az",
    },
    {
      code: "ba",
      name: "Bosanski",
      country_code: "ba",
    },
    {
      code: "bg",
      name: "български",
      country_code: "bg",
    },
    {
      code: "ir",
      name: "فارسی",
      country_code: "ir",
    },
    {
      code: "kz",
      name: "қазақ",
      country_code: "kz",
    },
    {
      code: "kg",
      name: "Киргиз",
      country_code: "kg",
    },
    {
      code: "mk",
      name: "македонски",
      country_code: "mk",
    },
    {
      code: "ru",
      name: "Русский",
      country_code: "ru",
    },
  ];

  const currentLanguageCode = cookies.get("i18next") || "tr";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    console.log("Setting page stuff");
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  const [projects, setProjects] = useState(data);

  const filterType = (category) => {
    setProjects(
      data.filter((item) => {
        return item.category === category;
      })
    );
  };

  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState("");

  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
  };

  return (
    <div className="max-w-[1640px] m-auto px-4 py-12">
      <div className="home-bannerImage-container">
        <img src={BannerBackground} alt="" />
      </div>
      <h1 className="text-gray-600 font-bold text-4xl text-center">
        {t("navbar-projeler")}
      </h1>
      <h3 className="text-gray-500 font-bold text-xl text-center mt-9 mb-9">
        {t("projeler-h3")}
      </h3>
      <h3 className="text-gray-500 font-bold text-xl text-center mt-9 mb-9">
        {t("projeler-h3-2")}
      </h3>

      <div className="flex flex-col lg:flex-row justify-between">
        <div>
          <p className="font-bold text-gray-700">{t("projeler-filtre")}</p>
          <div className="flex justfiy-between flex-wrap mb-6">
            <button
              onClick={() => setProjects(data)}
              className="m-1 border-gray-600 text-gray-600 hover:bg-gray-600 hover:text-white rounded-xl"
            >
              {t("projeler-filtre-tumu")}
            </button>
            <button
              onClick={() => filterType("celik")}
              className="m-1 border-gray-600 text-gray-600 hover:bg-gray-600 hover:text-white rounded-xl"
            >
              {t("projeler-filtre-celik")}
            </button>
            <button
              onClick={() => filterType("beton")}
              className="m-1 border-gray-600 text-gray-600 hover:bg-gray-600 hover:text-white rounded-xl"
            >
              {t("projeler-filtre-beton")}
            </button>
            <button
              onClick={() => filterType("tas")}
              className="m-1 border-gray-600 text-gray-600 hover:bg-gray-600 hover:text-white rounded-xl"
            >
              {t("projeler-filtre-tas")}
            </button>
            <button
              onClick={() => filterType("galeri")}
              className="m-1 border-gray-600 text-gray-600 hover:bg-gray-600 hover:text-white rounded-xl"
            >
              {t("projeler-filtre-galeri")}
            </button>
          </div>
        </div>
      </div>

      <div className={model ? "model open" : "model"}>
        <img src={tempimgSrc} alt="tempimg" />
        <FaWindowClose onClick={() => setModel(false)} className="x" />
      </div>
      <div className="gallery">
        {projects.map((item, index) => {
          return (
            <div className="pics" key={index}>
              <img
                src={item.image}
                alt="img"
                style={{ width: "100%" }}
                onClick={() => getImg(item.image)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Projects;
