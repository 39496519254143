import React, { useEffect } from "react";
import { AiFillFacebook } from "react-icons/ai";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";

function Footer() {
  const languages = [
    {
      code: "tr",
      name: "Türkçe",
      country_code: "tr",
    },
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
    {
      code: "ar",
      name: "العربية",
      country_code: "sa",
    },
    {
      code: "az",
      name: "Azərbaycan",
      country_code: "az",
    },
    {
      code: "ba",
      name: "Bosanski",
      country_code: "ba",
    },
    {
      code: "bg",
      name: "български",
      country_code: "bg",
    },
    {
      code: "ir",
      name: "فارسی",
      country_code: "ir",
    },
    {
      code: "kz",
      name: "қазақ",
      country_code: "kz",
    },
    {
      code: "kg",
      name: "Киргиз",
      country_code: "kg",
    },
    {
      code: "mk",
      name: "македонски",
      country_code: "mk",
    },
    {
      code: "ru",
      name: "Русский",
      country_code: "ru",
    },
  ];

  const currentLanguageCode = cookies.get("i18next") || "tr";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    console.log("Setting page stuff");
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  return (
    <div className="footer">
      <div className="footer_inside">
        <h3>{t("footerjs_Adres")}</h3>
        <p>
          Karacasu Karşıyaka Mahallesi 82083 Sokak Dulkadiroğlu Kahramanmaraş
        </p>
        <h3>Tel:</h3>
        <p>+90 543 550 73 76 Minareci Murat</p>
        <p>+90 543 799 27 81 İsa Can Dağ</p>
      </div>
      <a href="https://www.facebook.com/minarecimurad" className="footer-icons">
        <AiFillFacebook />
      </a>
    </div>
  );
}

export default Footer;
