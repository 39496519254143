import React, { useEffect } from "react";
import { Link, Route } from "react-router-dom";
import "./image.css";
import Mosque from "../assets/abdulhamid-khan-1.jpg";
import mosque_2 from "../assets/abdulhamithan_camii_2.jpg";
import { FiArrowRight } from "react-icons/fi";
import AboutBackground from "../assets/about-background.png";
import BannerBackground from "../assets/home-banner-background.png";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";

function Home() {
  const languages = [
    {
      code: "tr",
      name: "Türkçe",
      country_code: "tr",
    },
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
    {
      code: "ar",
      name: "العربية",
      country_code: "sa",
    },
    {
      code: "az",
      name: "Azərbaycan",
      country_code: "az",
    },
    {
      code: "ba",
      name: "Bosanski",
      country_code: "ba",
    },
    {
      code: "bg",
      name: "български",
      country_code: "bg",
    },
    {
      code: "ir",
      name: "فارسی",
      country_code: "ir",
    },
    {
      code: "kz",
      name: "қазақ",
      country_code: "kz",
    },
    {
      code: "kg",
      name: "Киргиз",
      country_code: "kg",
    },
    {
      code: "mk",
      name: "македонски",
      country_code: "mk",
    },
    {
      code: "ru",
      name: "Русский",
      country_code: "ru",
    },
  ];

  const currentLanguageCode = cookies.get("i18next") || "tr";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    console.log("Setting page stuff");
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  return (
    <div className="home-container">
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src={BannerBackground} alt="" />
        </div>
        <div className="home-text-section">
          <h1 className="primary-heading">{t("home-h1")}</h1>
          <p className="primary-text">{t("home-p")}</p>
          <Link to="/projeler">
            <button className="secondary-button">
              {t("home-btn-project-show")} <FiArrowRight />
            </button>
          </Link>
        </div>
        <div className="home-image-section">
          <img className="mosque" src={Mosque} alt="" />
        </div>
      </div>
      <div className="work-section-wrapper">
        <div className="about-background-image-container">
          <img src={AboutBackground} alt="" />
        </div>
        <div className="work-section-top">
          <h1 className="primary-heading">{t("aboutjs-slogan")}</h1>
        </div>
      </div>
      <div className="about-section-container">
        <div className="about-section-image-container">
          <img className="mosque" src={mosque_2} alt="" />
        </div>
        <div className="about-section-text-container">
          <h1 className="primary-heading">{t("home-h1-2")}</h1>
          <p className="primary-text">{t("home-p-2")}</p>
          <p className="primary-text">{t("home-p2-2")}</p>
          <Link to="/hakkimizda">
            <button className="secondary-button">
              {t("navbar-hakkimizda")}
              <FiArrowRight />
            </button>
          </Link>
        </div>
      </div>
      <div className="work-section-wrapper">
        <div className="work-section-top">
          <h1 className="primary-heading">{t("home-h1-stil")}</h1>
          <p className="primary-text">{t("home-h1-stil-p")}</p>
        </div>
      </div>
      <div className="contact-page-wrapper">
        <h1 className="primary-heading">{t("navbar-iletisim")}</h1>
        <h1 className="primary-heading">+90 543 550 73 76</h1>
        <h1 className="primary-heading-contact">+90 543 799 27 81</h1>
        <Link to="/iletisim">
          <button className="secondary-button">
            {t("navbar-iletisim")} <FiArrowRight />
          </button>
        </Link>
      </div>
    </div>
  );
}

export default Home;
