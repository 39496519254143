import a1 from "../assets/minare-galery/1.jpeg";
import a2 from "../assets/minare-galery/2.jpeg";
import a3 from "../assets/minare-galery/3.jpeg";
import a4 from "../assets/minare-galery/4.jpeg";
import a5 from "../assets/minare-galery/5.jpeg";
import a6 from "../assets/minare-galery/6.jpeg";
import a7 from "../assets/minare-galery/7.jpeg";
import a8 from "../assets/minare-galery/8.jpeg";
import a9 from "../assets/minare-galery/9.jpeg";
import a10 from "../assets/minare-galery/10.jpeg";
import a11 from "../assets/minare-galery/11.jpeg";
import a12 from "../assets/minare-galery/12.jpeg";
import a13 from "../assets/minare-galery/13.jpeg";
import a14 from "../assets/minare-galery/14.jpeg";
import a15 from "../assets/minare-galery/15.jpeg";
import a16 from "../assets/minare-galery/16.jpeg";
import a17 from "../assets/minare-galery/17.jpeg";
import a18 from "../assets/minare-galery/18.jpeg";
import a19 from "../assets/minare-galery/19.jpeg";
import a20 from "../assets/minare-galery/20.jpeg";
import a21 from "../assets/minare-galery/21.jpeg";
import a22 from "../assets/minare-galery/22.jpeg";
import a23 from "../assets/minare-galery/23.jpeg";
import a24 from "../assets/minare-galery/24.jpeg";
import a25 from "../assets/minare-galery/25.jpeg";
import a26 from "../assets/minare-galery/26.jpeg";
import a27 from "../assets/minare-galery/27.jpeg";
import a28 from "../assets/minare-galery/28.jpeg";
import a29 from "../assets/minare-galery/29.jpeg";
import a30 from "../assets/minare-galery/30.jpeg";
import a31 from "../assets/minare-galery/31.jpeg";
import a32 from "../assets/minare-galery/32.jpeg";
import a33 from "../assets/minare-galery/33.jpeg";
import a35 from "../assets/minare-galery/35.jpeg";
import a36 from "../assets/minare-galery/36.jpeg";
import a37 from "../assets/minare-galery/37.jpeg";
import a38 from "../assets/minare-galery/38.jpeg";
import a39 from "../assets/minare-galery/39.jpeg";
import a40 from "../assets/minare-galery/40.jpeg";
import a41 from "../assets/minare-galery/41.jpeg";
import a42 from "../assets/minare-galery/42.jpeg";
import a43 from "../assets/minare-galery/43.jpg";
import a44 from "../assets/minare-galery/44.jpg";
import a45 from "../assets/minare-galery/45.jpg";
import a46 from "../assets/minare-galery/46.jpg";
import a47 from "../assets/minare-galery/47.jpg";
import a48 from "../assets/minare-galery/48.jpg";
import a49 from "../assets/minare-galery/49.jpg";
import a50 from "../assets/minare-galery/50.jpeg";
import a51 from "../assets/minare-galery/51.jpg";
import a52 from "../assets/minare-galery/52.jpg";
import a53 from "../assets/minare-galery/53.jpg";
import a54 from "../assets/minare-galery/54.jpg";
import a55 from "../assets/minare-galery/55.jpg";
import a56 from "../assets/minare-galery/56.jpg";
import a57 from "../assets/minare-galery/57.jpeg";
import a58 from "../assets/minare-galery/58.jpeg";
import a59 from "../assets/minare-galery/59.jpeg";
import a60 from "../assets/minare-galery/60.jpeg";
import a61 from "../assets/minare-galery/61.jpeg";
import a62 from "../assets/minare-galery/62.jpeg";
import a63 from "../assets/minare-galery/63.jpeg";
import a64 from "../assets/minare-galery/64.jpeg";
import a65 from "../assets/minare-galery/65.jpeg";
import a66 from "../assets/minare-galery/66.jpeg";
import a67 from "../assets/minare-galery/67.jpeg";
import a68 from "../assets/minare-galery/68.jpeg";
import a69 from "../assets/minare-galery/69.jpeg";
import a70 from "../assets/minare-galery/70.jpeg";
import a71 from "../assets/minare-galery/71.jpeg";
import a72 from "../assets/minare-galery/72.jpeg";
import a73 from "../assets/minare-galery/73.jpeg";
import a74 from "../assets/minare-galery/74.jpeg";
import a75 from "../assets/minare-galery/75.jpeg";
import a76 from "../assets/minare-galery/76.jpeg";
import a77 from "../assets/minare-galery/77.jpeg";
import a78 from "../assets/minare-galery/78.jpeg";
import a79 from "../assets/minare-galery/79.jpeg";
import a80 from "../assets/minare-galery/80.jpeg";
import a81 from "../assets/minare-galery/81.jpeg";
import a82 from "../assets/minare-galery/82.jpeg";
import a83 from "../assets/minare-galery/83.jpeg";
import a84 from "../assets/minare-galery/84.jpeg";
import a85 from "../assets/minare-galery/85.jpg";
import a86 from "../assets/minare-galery/86.jpg";
import a87 from "../assets/minare-galery/87.jpg";
import a88 from "../assets/minare-galery/88.jpg";
import a89 from "../assets/minare-galery/89.jpg";
import a90 from "../assets/minare-galery/90.jpg";
import a91 from "../assets/minare-galery/91.jpg";
import a92 from "../assets/minare-galery/92.jpg";
import a93 from "../assets/minare-galery/93.jpg";
import a94 from "../assets/minare-galery/94.jpg";
import a95 from "../assets/minare-galery/95.jpg";
import a96 from "../assets/minare-galery/96.jpg";
import a97 from "../assets/minare-galery/97.jpg";
import a98 from "../assets/minare-galery/98.jpg";
import a99 from "../assets/minare-galery/99.jpg";
import a100 from "../assets/minare-galery/100.jpg";
import a101 from "../assets/minare-galery/101.jpg";
import a102 from "../assets/minare-galery/102.jpg";
import a103 from "../assets/minare-galery/103.jpg";
import a104 from "../assets/minare-galery/104.jpg";
import a105 from "../assets/minare-galery/105.jpg";
import a106 from "../assets/minare-galery/106.jpg";
import a107 from "../assets/minare-galery/107.jpg";
import a108 from "../assets/minare-galery/108.jpg";
import a109 from "../assets/minare-galery/109.jpg";
import a110 from "../assets/minare-galery/110.jpg";
import a111 from "../assets/minare-galery/111.jpg";
import a112 from "../assets/minare-galery/112.jpg";
import a113 from "../assets/minare-galery/113.jpg";
import a114 from "../assets/minare-galery/114.jpg";
import a115 from "../assets/minare-galery/115.jpg";
import a116 from "../assets/minare-galery/116.jpg";
import a117 from "../assets/minare-galery/117.jpg";
import a118 from "../assets/minare-galery/118.jpg";
import a119 from "../assets/minare-galery/119.jpg";
import a120 from "../assets/minare-galery/120.jpg";
import a121 from "../assets/minare-galery/121.jpg";
import a122 from "../assets/minare-galery/122.jpg";
import a123 from "../assets/minare-galery/123.jpg";
import a124 from "../assets/minare-galery/124.jpg";
import a125 from "../assets/minare-galery/125.jpg";
import a126 from "../assets/minare-galery/126.jpg";
import a127 from "../assets/minare-galery/127.jpg";
import a128 from "../assets/minare-galery/128.jpg";
import a129 from "../assets/minare-galery/129.jpg";
import a130 from "../assets/minare-galery/130.jpg";
import a131 from "../assets/minare-galery/131.jpg";
import a132 from "../assets/minare-galery/132.jpg";
import a133 from "../assets/minare-galery/133.jpg";
import a134 from "../assets/minare-galery/134.jpg";
import a135 from "../assets/minare-galery/135.jpg";
import a136 from "../assets/minare-galery/136.jpg";
import a137 from "../assets/minare-galery/137.jpg";
import a138 from "../assets/minare-galery/138.jpg";
import a140 from "../assets/minare-galery/140.jpg";
import a141 from "../assets/minare-galery/141.jpg";
import a142 from "../assets/minare-galery/142.jpg";
import a143 from "../assets/minare-galery/143.jpg";
import a144 from "../assets/minare-galery/144.jpg";
import a145 from "../assets/minare-galery/145.jpg";
import a146 from "../assets/minare-galery/146.jpg";
import a147 from "../assets/minare-galery/147.jpg";
import a148 from "../assets/minare-galery/148.jpg";
import a149 from "../assets/minare-galery/149.jpg";
import a150 from "../assets/minare-galery/150.jpg";
import a151 from "../assets/minare-galery/151.jpg";
import a152 from "../assets/minare-galery/152.jpg";
import a153 from "../assets/minare-galery/153.jpg";
import a154 from "../assets/minare-galery/154.jpg";
import a155 from "../assets/minare-galery/155.jpg";
import a156 from "../assets/minare-galery/156.jpg";
import a157 from "../assets/minare-galery/157.jpg";
import a158 from "../assets/minare-galery/158.jpg";
import a159 from "../assets/minare-galery/159.jpg";
import a160 from "../assets/minare-galery/160.jpg";
import a161 from "../assets/minare-galery/161.jpg";
import a162 from "../assets/minare-galery/162.jpg";
import a163 from "../assets/minare-galery/163.jpg";
import a164 from "../assets/minare-galery/164.jpg";
import a165 from "../assets/minare-galery/165.jpg";
import a166 from "../assets/minare-galery/166.jpg";
import a167 from "../assets/minare-galery/167.jpg";
import a168 from "../assets/minare-galery/168.jpg";
import a169 from "../assets/minare-galery/169.jpg";
import a170 from "../assets/minare-galery/170.jpg";
import a171 from "../assets/minare-galery/171.jpg";
import a172 from "../assets/minare-galery/172.jpg";
import a173 from "../assets/minare-galery/173.jpg";
import a174 from "../assets/minare-galery/174.jpg";
import a175 from "../assets/minare-galery/175.jpg";
import a176 from "../assets/minare-galery/176.jpg";
import a177 from "../assets/minare-galery/177.jpg";
import a178 from "../assets/minare-galery/178.jpg";
import a179 from "../assets/minare-galery/179.jpg";
import a180 from "../assets/minare-galery/180.jpg";
import a181 from "../assets/minare-galery/181.jpg";
import a182 from "../assets/minare-galery/182.jpg";
import a183 from "../assets/minare-galery/183.jpg";
import a184 from "../assets/minare-galery/184.jpg";
import a186 from "../assets/minare-galery/186.jpg";
import a187 from "../assets/minare-galery/187.jpg";
import a188 from "../assets/minare-galery/188.jpg";
import a189 from "../assets/minare-galery/189.jpg";
import a190 from "../assets/minare-galery/190.jpg";
import a191 from "../assets/minare-galery/191.jpg";
import a192 from "../assets/minare-galery/192.jpg";
import a193 from "../assets/minare-galery/193.jpg";
import a194 from "../assets/minare-galery/194.jpg";
import a195 from "../assets/minare-galery/195.jpg";
import a196 from "../assets/minare-galery/196.jpg";
import a197 from "../assets/minare-galery/197.jpg";
import a198 from "../assets/minare-galery/198.jpg";
import a199 from "../assets/minare-galery/199.jpg";
import a200 from "../assets/minare-galery/200.jpg";
import a201 from "../assets/minare-galery/201.jpg";
import a202 from "../assets/minare-galery/202.jpg";
import a203 from "../assets/minare-galery/203.jpg";
import a204 from "../assets/minare-galery/204.jpg";
import a205 from "../assets/minare-galery/205.jpg";
import a206 from "../assets/minare-galery/206.jpg";
import a207 from "../assets/minare-galery/207.jpg";
import a208 from "../assets/minare-galery/208.jpg";
import a209 from "../assets/minare-galery/209.jpg";
import a210 from "../assets/minare-galery/210.jpg";
import a211 from "../assets/minare-galery/211.jpg";
import a212 from "../assets/minare-galery/212.jpg";
import a213 from "../assets/minare-galery/213.jpg";
import a214 from "../assets/minare-galery/214.jpg";
import a215 from "../assets/minare-galery/215.jpg";
import a216 from "../assets/minare-galery/216.jpg";
import a217 from "../assets/minare-galery/217.jpg";
import a218 from "../assets/minare-galery/218.jpg";
import a219 from "../assets/minare-galery/219.jpg";
import a220 from "../assets/minare-galery/220.jpg";
import a221 from "../assets/minare-galery/221.jpg";
import a222 from "../assets/minare-galery/222.jpg";
import a223 from "../assets/minare-galery/223.jpg";
import a224 from "../assets/minare-galery/224.jpg";
import a225 from "../assets/minare-galery/225.jpg";
import a226 from "../assets/minare-galery/226.jpg";
import a227 from "../assets/minare-galery/227.jpg";
import a228 from "../assets/minare-galery/228.jpg";
import a229 from "../assets/minare-galery/229.jpg";
import a230 from "../assets/minare-galery/230.jpg";
import a231 from "../assets/minare-galery/231.jpg";
import a232 from "../assets/minare-galery/232.jpg";
import a233 from "../assets/minare-galery/233.jpg";
import a234 from "../assets/minare-galery/234.jpg";
import a235 from "../assets/minare-galery/235.jpg";
import a236 from "../assets/minare-galery/236.jpg";
import a237 from "../assets/minare-galery/237.jpg";
import a238 from "../assets/minare-galery/238.jpg";
import a239 from "../assets/minare-galery/239.jpg";
import a240 from "../assets/minare-galery/240.jpg";
import a241 from "../assets/minare-galery/241.jpg";
import a242 from "../assets/minare-galery/242.jpg";
import a243 from "../assets/minare-galery/243.jpg";
import a244 from "../assets/minare-galery/244.jpg";
import a245 from "../assets/minare-galery/245.jpg";
import a246 from "../assets/minare-galery/246.jpg";
import a247 from "../assets/minare-galery/247.jpg";
import a248 from "../assets/minare-galery/248.jpg";
import a249 from "../assets/minare-galery/249.jpg";
import a250 from "../assets/minare-galery/250.jpg";
import a251 from "../assets/minare-galery/251.jpg";
import a252 from "../assets/minare-galery/252.jpg";
import a253 from "../assets/minare-galery/253.jpg";
import a254 from "../assets/minare-galery/254.jpg";
import a255 from "../assets/minare-galery/255.jpg";
import a256 from "../assets/minare-galery/256.jpg";
import a257 from "../assets/minare-galery/257.jpg";
import a258 from "../assets/minare-galery/258.jpg";
import a259 from "../assets/minare-galery/259.jpg";
import a260 from "../assets/minare-galery/260.jpg";
import a261 from "../assets/minare-galery/261.jpg";
import a262 from "../assets/minare-galery/262.jpg";
import a263 from "../assets/minare-galery/263.jpg";
import a264 from "../assets/minare-galery/264.jpg";
import a265 from "../assets/minare-galery/265.jpg";
import a266 from "../assets/minare-galery/266.jpg";
import a267 from "../assets/minare-galery/267.jpg";
import a268 from "../assets/minare-galery/268.jpg";
import a269 from "../assets/minare-galery/269.jpg";
import a270 from "../assets/minare-galery/270.jpg";
import a271 from "../assets/minare-galery/271.jpg";
import a272 from "../assets/minare-galery/272.jpg";
import a273 from "../assets/minare-galery/273.jpg";
import a274 from "../assets/minare-galery/274.jpg";
import a275 from "../assets/minare-galery/275.jpg";
import a276 from "../assets/minare-galery/276.jpg";
import a277 from "../assets/minare-galery/277.jpg";
import a278 from "../assets/minare-galery/278.jpg";
import a279 from "../assets/minare-galery/279.jpg";
import a280 from "../assets/minare-galery/280.jpg";
import a281 from "../assets/minare-galery/281.jpg";
import a282 from "../assets/minare-galery/282.jpg";
import a283 from "../assets/minare-galery/283.jpg";
import a284 from "../assets/minare-galery/284.jpg";
import a285 from "../assets/minare-galery/285.jpg";
import a286 from "../assets/minare-galery/286.jpg";
import a287 from "../assets/minare-galery/287.jpg";
import a288 from "../assets/minare-galery/288.jpg";
import a289 from "../assets/minare-galery/289.jpg";

export const data = [
  {
    id: 1,
    category: "beton",
    image: a1,
  },
  {
    id: 2,
    category: "celik",
    image: a2,
  },
  {
    id: 3,
    category: "beton",
    image: a3,
  },
  {
    id: 4,
    category: "galeri",
    image: a4,
  },

  {
    id: 5,
    category: "beton",
    image: a5,
  },
  {
    id: 6,
    category: "beton",
    image: a6,
  },
  {
    id: 7,
    category: "beton",
    image: a7,
  },

  {
    id: 8,
    category: "beton",
    image: a8,
  },
  {
    id: 9,
    category: "galeri",
    image: a9,
  },
  {
    id: 10,
    category: "beton",
    image: a10,
  },

  {
    id: 11,
    category: "beton",
    image: a11,
  },
  {
    id: 12,
    category: "beton",
    image: a12,
  },
  {
    id: 13,
    category: "beton",
    image: a13,
  },

  {
    id: 14,
    category: "tas",
    image: a14,
  },
  {
    id: 15,
    category: "celik",
    image: a15,
  },
  {
    id: 16,
    category: "beton",
    image: a16,
  },
  {
    id: 17,
    category: "beton",
    image: a17,
  },
  {
    id: 18,
    category: "beton",
    image: a18,
  },

  {
    id: 19,
    category: "beton",
    image: a19,
  },
  {
    id: 20,
    category: "beton",
    image: a20,
  },
  {
    id: 21,
    category: "beton",
    image: a21,
  },

  {
    id: 22,
    category: "beton",
    image: a22,
  },
  {
    id: 23,
    category: "beton",
    image: a23,
  },
  {
    id: 24,
    category: "galeri",
    image: a24,
  },

  {
    id: 25,
    category: "tas",
    image: a25,
  },
  {
    id: 26,
    category: "beton",
    image: a26,
  },
  {
    id: 27,
    category: "beton",
    image: a27,
  },

  {
    id: 28,
    category: "beton",
    image: a28,
  },
  {
    id: 29,
    category: "celik",
    image: a29,
  },
  {
    id: 30,
    category: "tas",
    image: a30,
  },
  {
    id: 31,
    category: "galeri",
    image: a31,
  },

  {
    id: 32,
    category: "beton",
    image: a32,
  },
  {
    id: 33,
    category: "tas",
    image: a33,
  },

  {
    id: 35,
    category: "galeri",
    image: a35,
  },

  {
    id: 36,
    category: "tas",
    image: a36,
  },

  {
    id: 37,
    category: "beton",
    image: a37,
  },
  {
    id: 38,
    category: "tas",
    image: a38,
  },
  {
    id: 39,
    category: "beton",
    image: a39,
  },
  {
    id: 40,
    category: "galeri",
    image: a40,
  },

  {
    id: 41,
    category: "beton",
    image: a41,
  },
  {
    id: 42,
    category: "beton",
    image: a42,
  },
  {
    id: 43,
    category: "beton",
    image: a43,
  },
  {
    id: 44,
    category: "beton",
    image: a44,
  },
  {
    id: 45,
    category: "tas",
    image: a45,
  },
  {
    id: 46,
    category: "beton",
    image: a46,
  },
  {
    id: 47,
    category: "tas",
    image: a47,
  },
  {
    id: 48,
    category: "beton",
    image: a48,
  },
  {
    id: 49,
    category: "beton",
    image: a49,
  },
  {
    id: 50,
    category: "beton",
    image: a50,
  },
  {
    id: 51,
    category: "beton",
    image: a51,
  },
  {
    id: 52,
    category: "beton",
    image: a52,
  },
  {
    id: 53,
    category: "beton",
    image: a53,
  },
  {
    id: 54,
    category: "beton",
    image: a54,
  },
  {
    id: 55,
    category: "beton",
    image: a55,
  },
  {
    id: 56,
    category: "beton",
    image: a56,
  },
  {
    id: 57,
    category: "galeri",
    image: a57,
  },
  {
    id: 58,
    category: "celik",
    image: a58,
  },

  {
    id: 59,
    category: "beton",
    image: a59,
  },
  {
    id: 60,
    category: "celik",
    image: a60,
  },
  {
    id: 61,
    category: "beton",
    image: a61,
  },

  {
    id: 62,
    category: "beton",
    image: a62,
  },
  {
    id: 63,
    category: "beton",
    image: a63,
  },

  {
    id: 64,
    category: "tas",
    image: a64,
  },
  {
    id: 65,
    category: "tas",
    image: a65,
  },
  {
    id: 66,
    category: "tas",
    image: a66,
  },
  {
    id: 67,
    category: "beton",
    image: a67,
  },
  {
    id: 68,
    category: "galeri",
    image: a68,
  },

  {
    id: 69,
    category: "celik",
    image: a69,
  },
  {
    id: 70,
    category: "beton",
    image: a70,
  },
  {
    id: 71,
    category: "beton",
    image: a71,
  },

  {
    id: 72,
    category: "celik",
    image: a72,
  },
  {
    id: 73,
    category: "celik",
    image: a73,
  },
  {
    id: 74,
    category: "beton",
    image: a74,
  },

  {
    id: 75,
    category: "beton",
    image: a75,
  },
  {
    id: 76,
    category: "celik",
    image: a76,
  },
  {
    id: 77,
    category: "beton",
    image: a77,
  },
  {
    id: 78,
    category: "beton",
    image: a78,
  },
  {
    id: 79,
    category: "beton",
    image: a79,
  },
  {
    id: 80,
    category: "celik",
    image: a80,
  },
  {
    id: 81,
    category: "beton",
    image: a81,
  },
  {
    id: 82,
    category: "beton",
    image: a82,
  },
  {
    id: 83,
    category: "tas",
    image: a83,
  },
  {
    id: 84,
    category: "beton",
    image: a84,
  },
  {
    id: 85,
    category: "celik",
    image: a85,
  },
  {
    id: 86,
    category: "celik",
    image: a86,
  },
  {
    id: 87,
    category: "galeri",
    image: a87,
  },
  {
    id: 88,
    category: "celik",
    image: a88,
  },
  {
    id: 89,
    category: "celik",
    image: a89,
  },
  {
    id: 90,
    category: "celik",
    image: a90,
  },
  {
    id: 91,
    category: "celik",
    image: a91,
  },
  {
    id: 92,
    category: "celik",
    image: a92,
  },
  {
    id: 93,
    category: "celik",
    image: a93,
  },
  {
    id: 94,
    category: "celik",
    image: a94,
  },
  {
    id: 95,
    category: "celik",
    image: a95,
  },
  {
    id: 96,
    category: "celik",
    image: a96,
  },
  {
    id: 97,
    category: "celik",
    image: a97,
  },
  {
    id: 98,
    category: "galeri",
    image: a98,
  },
  {
    id: 99,
    category: "celik",
    image: a99,
  },
  {
    id: 100,
    category: "celik",
    image: a100,
  },
  {
    id: 101,
    category: "celik",
    image: a101,
  },
  {
    id: 102,
    category: "celik",
    image: a102,
  },
  {
    id: 103,
    category: "celik",
    image: a103,
  },
  {
    id: 104,
    category: "celik",
    image: a104,
  },
  {
    id: 105,
    category: "celik",
    image: a105,
  },
  {
    id: 106,
    category: "galeri",
    image: a106,
  },
  {
    id: 107,
    category: "galeri",
    image: a107,
  },
  {
    id: 108,
    category: "celik",
    image: a108,
  },
  {
    id: 109,
    category: "celik",
    image: a109,
  },
  {
    id: 110,
    category: "celik",
    image: a110,
  },
  {
    id: 111,
    category: "celik",
    image: a111,
  },
  {
    id: 112,
    category: "celik",
    image: a112,
  },
  {
    id: 113,
    category: "celik",
    image: a113,
  },
  {
    id: 114,
    category: "galeri",
    image: a114,
  },
  {
    id: 115,
    category: "celik",
    image: a115,
  },
  {
    id: 116,
    category: "galeri",
    image: a116,
  },
  {
    id: 117,
    category: "galeri",
    image: a117,
  },
  {
    id: 118,
    category: "galeri",
    image: a118,
  },
  {
    id: 119,
    category: "celik",
    image: a119,
  },
  {
    id: 120,
    category: "celik",
    image: a120,
  },
  {
    id: 121,
    category: "celik",
    image: a121,
  },
  {
    id: 122,
    category: "celik",
    image: a122,
  },
  {
    id: 123,
    category: "celik",
    image: a123,
  },
  {
    id: 124,
    category: "celik",
    image: a124,
  },
  {
    id: 125,
    category: "celik",
    image: a125,
  },
  {
    id: 126,
    category: "celik",
    image: a126,
  },
  {
    id: 127,
    category: "celik",
    image: a127,
  },
  {
    id: 128,
    category: "celik",
    image: a128,
  },
  {
    id: 129,
    category: "celik",
    image: a129,
  },
  {
    id: 130,
    category: "celik",
    image: a130,
  },
  {
    id: 131,
    category: "celik",
    image: a131,
  },
  {
    id: 132,
    category: "celik",
    image: a132,
  },
  {
    id: 133,
    category: "celik",
    image: a133,
  },
  {
    id: 134,
    category: "celik",
    image: a134,
  },
  {
    id: 135,
    category: "celik",
    image: a135,
  },
  {
    id: 136,
    category: "celik",
    image: a136,
  },
  {
    id: 137,
    category: "celik",
    image: a137,
  },
  {
    id: 138,
    category: "celik",
    image: a138,
  },
  {
    id: 140,
    category: "galeri",
    image: a140,
  },
  {
    id: 141,
    category: "celik",
    image: a141,
  },
  {
    id: 142,
    category: "celik",
    image: a142,
  },
  {
    id: 143,
    category: "galeri",
    image: a143,
  },
  {
    id: 144,
    category: "celik",
    image: a144,
  },
  {
    id: 145,
    category: "celik",
    image: a145,
  },
  {
    id: 146,
    category: "celik",
    image: a146,
  },
  {
    id: 147,
    category: "celik",
    image: a147,
  },
  {
    id: 148,
    category: "celik",
    image: a148,
  },
  {
    id: 149,
    category: "celik",
    image: a149,
  },
  {
    id: 150,
    category: "galeri",
    image: a150,
  },
  {
    id: 151,
    category: "celik",
    image: a151,
  },
  {
    id: 152,
    category: "celik",
    image: a152,
  },
  {
    id: 153,
    category: "galeri",
    image: a153,
  },
  {
    id: 154,
    category: "galeri",
    image: a154,
  },
  {
    id: 155,
    category: "celik",
    image: a155,
  },
  {
    id: 156,
    category: "celik",
    image: a156,
  },
  {
    id: 157,
    category: "celik",
    image: a157,
  },
  {
    id: 158,
    category: "celik",
    image: a158,
  },
  {
    id: 159,
    category: "celik",
    image: a159,
  },
  {
    id: 160,
    category: "celik",
    image: a160,
  },
  {
    id: 161,
    category: "galeri",
    image: a161,
  },
  {
    id: 162,
    category: "celik",
    image: a162,
  },
  {
    id: 163,
    category: "celik",
    image: a163,
  },
  {
    id: 164,
    category: "celik",
    image: a164,
  },
  {
    id: 165,
    category: "celik",
    image: a165,
  },
  {
    id: 166,
    category: "celik",
    image: a166,
  },
  {
    id: 167,
    category: "celik",
    image: a167,
  },
  {
    id: 168,
    category: "celik",
    image: a168,
  },
  {
    id: 169,
    category: "celik",
    image: a169,
  },
  {
    id: 170,
    category: "celik",
    image: a170,
  },
  {
    id: 171,
    category: "celik",
    image: a171,
  },
  {
    id: 172,
    category: "celik",
    image: a172,
  },
  {
    id: 173,
    category: "celik",
    image: a173,
  },
  {
    id: 174,
    category: "celik",
    image: a174,
  },
  {
    id: 175,
    category: "celik",
    image: a175,
  },
  {
    id: 176,
    category: "celik",
    image: a176,
  },
  {
    id: 177,
    category: "celik",
    image: a177,
  },
  {
    id: 178,
    category: "celik",
    image: a178,
  },
  {
    id: 179,
    category: "celik",
    image: a179,
  },
  {
    id: 180,
    category: "celik",
    image: a180,
  },
  {
    id: 181,
    category: "celik",
    image: a181,
  },
  {
    id: 182,
    category: "celik",
    image: a182,
  },
  {
    id: 183,
    category: "beton",
    image: a183,
  },
  {
    id: 184,
    category: "celik",
    image: a184,
  },
  {
    id: 186,
    category: "celik",
    image: a186,
  },
  {
    id: 187,
    category: "celik",
    image: a187,
  },
  {
    id: 188,
    category: "celik",
    image: a188,
  },
  {
    id: 189,
    category: "celik",
    image: a189,
  },
  {
    id: 190,
    category: "celik",
    image: a190,
  },
  {
    id: 191,
    category: "celik",
    image: a191,
  },
  {
    id: 192,
    category: "celik",
    image: a192,
  },
  {
    id: 193,
    category: "celik",
    image: a193,
  },
  {
    id: 194,
    category: "celik",
    image: a194,
  },
  {
    id: 195,
    category: "celik",
    image: a195,
  },
  {
    id: 196,
    category: "celik",
    image: a196,
  },
  {
    id: 197,
    category: "celik",
    image: a197,
  },
  {
    id: 198,
    category: "celik",
    image: a198,
  },
  {
    id: 199,
    category: "celik",
    image: a199,
  },
  {
    id: 200,
    category: "galeri",
    image: a200,
  },
  {
    id: 201,
    category: "celik",
    image: a201,
  },
  {
    id: 202,
    category: "celik",
    image: a202,
  },
  {
    id: 203,
    category: "celik",
    image: a203,
  },
  {
    id: 204,
    category: "celik",
    image: a204,
  },
  {
    id: 205,
    category: "beton",
    image: a205,
  },
  {
    id: 206,
    category: "celik",
    image: a206,
  },
  {
    id: 207,
    category: "celik",
    image: a207,
  },
  {
    id: 208,
    category: "celik",
    image: a208,
  },
  {
    id: 209,
    category: "celik",
    image: a209,
  },
  {
    id: 210,
    category: "celik",
    image: a210,
  },
  {
    id: 211,
    category: "beton",
    image: a211,
  },
  {
    id: 212,
    category: "galeri",
    image: a212,
  },
  {
    id: 213,
    category: "celik",
    image: a213,
  },
  {
    id: 214,
    category: "celik",
    image: a214,
  },
  {
    id: 215,
    category: "celik",
    image: a215,
  },
  {
    id: 216,
    category: "celik",
    image: a216,
  },
  {
    id: 217,
    category: "celik",
    image: a217,
  },
  {
    id: 218,
    category: "celik",
    image: a218,
  },
  {
    id: 219,
    category: "celik",
    image: a219,
  },
  {
    id: 220,
    category: "celik",
    image: a220,
  },
  {
    id: 221,
    category: "celik",
    image: a221,
  },
  {
    id: 222,
    category: "celik",
    image: a222,
  },
  {
    id: 223,
    category: "celik",
    image: a223,
  },
  {
    id: 224,
    category: "celik",
    image: a224,
  },
  {
    id: 225,
    category: "celik",
    image: a225,
  },
  {
    id: 226,
    category: "celik",
    image: a226,
  },
  {
    id: 227,
    category: "celik",
    image: a227,
  },
  {
    id: 228,
    category: "celik",
    image: a228,
  },
  {
    id: 229,
    category: "celik",
    image: a229,
  },
  {
    id: 230,
    category: "celik",
    image: a230,
  },
  {
    id: 231,
    category: "galeri",
    image: a231,
  },
  {
    id: 232,
    category: "celik",
    image: a232,
  },
  {
    id: 233,
    category: "celik",
    image: a233,
  },
  {
    id: 234,
    category: "celik",
    image: a234,
  },
  {
    id: 235,
    category: "celik",
    image: a235,
  },
  {
    id: 236,
    category: "celik",
    image: a236,
  },
  {
    id: 237,
    category: "celik",
    image: a237,
  },
  {
    id: 238,
    category: "celik",
    image: a238,
  },
  {
    id: 239,
    category: "celik",
    image: a239,
  },
  {
    id: 240,
    category: "celik",
    image: a240,
  },
  {
    id: 241,
    category: "celik",
    image: a241,
  },
  {
    id: 242,
    category: "celik",
    image: a242,
  },
  {
    id: 243,
    category: "celik",
    image: a243,
  },
  {
    id: 244,
    category: "celik",
    image: a244,
  },
  {
    id: 245,
    category: "celik",
    image: a245,
  },
  {
    id: 246,
    category: "celik",
    image: a246,
  },
  {
    id: 247,
    category: "celik",
    image: a247,
  },
  {
    id: 248,
    category: "celik",
    image: a248,
  },
  {
    id: 249,
    category: "celik",
    image: a249,
  },
  {
    id: 250,
    category: "celik",
    image: a250,
  },
  {
    id: 251,
    category: "celik",
    image: a251,
  },
  {
    id: 252,
    category: "celik",
    image: a252,
  },
  {
    id: 253,
    category: "celik",
    image: a253,
  },
  {
    id: 254,
    category: "celik",
    image: a254,
  },
  {
    id: 255,
    category: "celik",
    image: a255,
  },
  {
    id: 256,
    category: "celik",
    image: a256,
  },
  {
    id: 257,
    category: "celik",
    image: a257,
  },
  {
    id: 258,
    category: "celik",
    image: a258,
  },
  {
    id: 259,
    category: "celik",
    image: a259,
  },
  {
    id: 260,
    category: "celik",
    image: a260,
  },
  {
    id: 261,
    category: "celik",
    image: a261,
  },
  {
    id: 262,
    category: "celik",
    image: a262,
  },
  {
    id: 263,
    category: "celik",
    image: a263,
  },
  {
    id: 264,
    category: "celik",
    image: a264,
  },
  {
    id: 265,
    category: "celik",
    image: a265,
  },
  {
    id: 266,
    category: "celik",
    image: a266,
  },
  {
    id: 267,
    category: "celik",
    image: a267,
  },
  {
    id: 268,
    category: "celik",
    image: a268,
  },
  {
    id: 269,
    category: "celik",
    image: a269,
  },
  {
    id: 270,
    category: "celik",
    image: a270,
  },
  {
    id: 271,
    category: "celik",
    image: a271,
  },
  {
    id: 272,
    category: "celik",
    image: a272,
  },
  {
    id: 273,
    category: "celik",
    image: a273,
  },
  {
    id: 274,
    category: "celik",
    image: a274,
  },
  {
    id: 275,
    category: "celik",
    image: a275,
  },
  {
    id: 276,
    category: "celik",
    image: a276,
  },
  {
    id: 277,
    category: "celik",
    image: a277,
  },
  {
    id: 278,
    category: "celik",
    image: a278,
  },
  {
    id: 279,
    category: "celik",
    image: a279,
  },
  {
    id: 280,
    category: "celik",
    image: a280,
  },
  {
    id: 281,
    category: "celik",
    image: a281,
  },
  {
    id: 282,
    category: "celik",
    image: a282,
  },
  {
    id: 283,
    category: "celik",
    image: a283,
  },
  {
    id: 284,
    category: "celik",
    image: a284,
  },
  {
    id: 285,
    category: "celik",
    image: a285,
  },
  {
    id: 286,
    category: "celik",
    image: a286,
  },
  {
    id: 287,
    category: "celik",
    image: a287,
  },
  {
    id: 288,
    category: "celik",
    image: a288,
  },
  {
    id: 289,
    category: "celik",
    image: a289,
  }
];
