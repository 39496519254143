import React, { useEffect } from "react";
import Mosque from "../assets/abdulhamithan_camii_2.jpg";
import BannerBackground from "../assets/home-banner-background.png";
import AboutBackground from "../assets/about-background.png";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";

function About() {
  const languages = [
    {
      code: "tr",
      name: "Türkçe",
      country_code: "tr",
    },
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
    {
      code: "ar",
      name: "العربية",
      country_code: "sa",
    },
    {
      code: "az",
      name: "Azərbaycan",
      country_code: "az",
    },
    {
      code: "ba",
      name: "Bosanski",
      country_code: "ba",
    },
    {
      code: "bg",
      name: "български",
      country_code: "bg",
    },
    {
      code: "ir",
      name: "فارسی",
      country_code: "ir",
    },
    {
      code: "kz",
      name: "қазақ",
      country_code: "kz",
    },
    {
      code: "kg",
      name: "Киргиз",
      country_code: "kg",
    },
    {
      code: "mk",
      name: "македонски",
      country_code: "mk",
    },
    {
      code: "ru",
      name: "Русский",
      country_code: "ru",
    },
  ];

  const currentLanguageCode = cookies.get("i18next") || "tr";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    console.log("Setting page stuff");
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  return (
    <div className="about">
      <div className="aboutTop"></div>
      <div className="home-bannerImage-container">
        <img src={BannerBackground} alt="" />
      </div>
      <div className="aboutBottom">
        <h1 className="h1_about"></h1>
        <h2 className=" text-gray-600 font-bold text-4xl text-center">
          {t("aboutjs-slogan")}
        </h2>
        <p className="text-gray-500 font-bold text-xl text-center mt-9 mb-9">
          {t("aboutjs-about")}
        </p>
        <div className="home-image-section">
          <img className="mosque" src={Mosque} alt="" />
        </div>
        <ul className="h1_about">
          <div className="about-background-image-container">
            <img src={AboutBackground} alt="" />
          </div>
          <li className="text-gray-500 font-bold text-xl text-center mt-9 mb-9">
            {t("aboutjs-s1")}
          </li>

          <li className="text-gray-500 font-bold text-xl text-center mt-9 mb-9">
            {t("aboutjs-s2")}
          </li>

          <li className="text-gray-500 font-bold text-xl text-center mt-9 mb-9">
            {t("aboutjs-s3")}
          </li>

          <li className="text-gray-500 font-bold text-xl text-center mt-9 mb-9">
            {t("aboutjs-s4")}
          </li>

          <li className="text-gray-500 font-bold text-xl text-center mt-9 mb-9">
            {t("aboutjs-s5")}
          </li>

          <li className="text-gray-500 font-bold text-xl text-center mt-9 mb-9">
            {t("aboutjs-s6")}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default About;
